import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Resource, Paragraph, List } from 'components/Landing/Resource/Resource'

const Terms: React.FC<RouteComponentProps> = () => {
  return (
    <Resource title='Terms of Use'>
      <Paragraph>
        Last updated on 1 March 2020.
        <br /> By accessing our services, you are agreeing to be bound by these
        terms of use, all applicable laws and regulations, and agree that you
        are responsible for compliance with any applicable local laws. If you do
        not agree with any of these terms, you are prohibited from using or
        accessing our services. We reserve the right, in our sole discretion, to
        make changes or modifications to these Terms of Use at any time and for
        any reason, without notice.
      </Paragraph>
      <Paragraph>
        The materials on Dose&apos;s website and apps are provided on an
        &apos;as is&apos; basis. Dose makes no warranties, expressed or implied,
        and hereby disclaims and negates all other warranties including, without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property
        or other violation of rights. Further, we do not warrant or make any
        representations concerning the accuracy, likely results, or reliability
        of the use of the materials provided by our services or otherwise
        relating to such materials on any services linked.
      </Paragraph>
      <Paragraph>
        In no event shall Dose or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption) arising out of the use or inability to use
        the materials provided by Dose&apos;s services, even if Dose or a Dose
        authorized representative has been notified orally or in writing of the
        possibility of such damage. Because some jurisdictions do not allow
        limitations on implied warranties, or limitations of liability for
        consequential or incidental damages, these limitations may not apply to
        you.
      </Paragraph>
      <Paragraph>
        You understand that the Services are not medical or healthcare services,
        and that no physician-patient relationship exists. YOU HEREBY AGREE
        THAT, BEFORE USING THE SERVICES, YOU WILL CONSULT YOUR PHYSICIAN,
        PARTICULARLY IF YOU ARE AT RISK FOR PROBLEMS RESULTING FROM CHANGES IN
        YOUR DIET OR LIFESTYLE. You affirm that a physician has specifically
        approved your use of the Services. The Services are intended for use
        only by individuals healthy enough to make changes to their diets and
        lifestyles, and are not intended for use by minors or individuals with
        any type of health condition that makes the kind of changes to diet or
        lifestyle suggested by the Services unsafe or inappropriate. You should
        discontinue diet or other lifestyle changes in cases where such changes
        cause pain or severe discomfort, and should consult a medical expert
        prior to returning to diet or other lifestyle changes in such cases. We
        reserve the right to deny you access to the Services for any reason or
        no reason, including if we determine, in our sole discretion, that you
        have certain medical conditions.
      </Paragraph>
      <Paragraph>
        Permission is granted to temporarily download one copy of the materials
        (information or software) on Dose&apos;s website. This is the grant of a
        license, not a transfer of title, and under this license you may not:
        <List>
          <li>modify or copy the materials;</li>
          <li>
            attempt to decompile or reverse engineer any software contained on
            Dose&apos;s website;
          </li>
          <li>
            remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            transfer the materials to another person or &quot;mirror&quot; the
            materials on any other server.
          </li>
        </List>
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by Dose at any time. Upon terminating
        your viewing of these materials or upon the termination of this license,
        you must destroy any downloaded materials in your possession whether in
        electronic or printed format.
      </Paragraph>
    </Resource>
  )
}

export default Terms
